body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body,
input {
  font-family: "Open Sans", sans-serif;
}

button.ui.primary.button {
  background-color: #3e4976;
}

button.ui.cancel.button {
  color: #0c0c0c;
}

.ui.breadcrumb a {
  color: inherit;
}

.ui.card {
  width: 400px;
}
